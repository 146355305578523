import {
  Button,
  Col,
  Icon,
  message,
  Modal,
  Popconfirm,
  Row,
  Table
} from "antd";
import gql from "graphql-tag";
import React, { useContext, useState } from "react";
import XLSX from "xlsx";
import { getGeocode } from "../../helpers/helpers";
import { ServicesContext } from "../../ServicesContext";
import { OperationalForm } from "../main/OperationalForm";
import ServiceForm from "./ServiceForm";
// import { useSubscription } from "@apollo/react-hooks";
import { DatePicker } from "antd";
import { Provider, createClient, useQuery } from "urql";
// import {
//  } from 'urql';
import moment from "moment";

const { RangePicker } = DatePicker;

// TODO: Get only services for a specific user
export const SERVICE_FRAGMENT = gql`
  fragment Service on service {
    id
    services
    updated_at
  }
`;

export const ALL_SERVICES = gql`
  ${SERVICE_FRAGMENT}
  subscription AllServices {
    service {
      ...Service
    }
  }
`;

const columns = [
  {
    title: "Nome",
    dataIndex: "client.name"
  },
  {
    title: "Endereço",
    dataIndex: "location.address"
  },
  {
    title: "Inicio da Operação",
    dataIndex: "service_start"
  },
  {
    title: "Fim da operação",
    dataIndex: "service_finish"
  },
  {
    title: "Peso",
    dataIndex: "weight",
    render: text => `${text} Kg`
  },
  {
    title: "Volume",
    dataIndex: "volume",
    render: text => `${text} m³`
  }
];

const extractData = async item => ({
  weight: item["C"],
  volume: item["D"],
  service_start: "08:00:00",
  service_finish: "18:00:00",
  operational_duration: "00:20:00",
  client: {
    name: item["A"]
  },
  location: {
    address: item["E"],
    description: item["B"],
    ...(await getGeocode(item["E"]))
  }
});

const StepOne = () => {
  const [modal, setModal] = useState(false);
  const [uploadModal, setuploadModal] = useState(false);
  const { services, addService, deleteService } = useContext(ServicesContext);
  // const { data } = useSubscription(ALL_SERVICES);

  const loadServices = async filteredServices => {
    filteredServices.map(async ({ client, address, weight, volume }) => {
      const s = {
        volume: volume,
        weight: weight,
        service_start: "08:00:00",
        service_finish: "18:00:00",
        operational_duration: "00:20:00",
        client: { name: client },
        location: {
          address: address,
          description: client,
          ...(await getGeocode(address))
        }
      };
      addService(s);
    });
  };

  const onImportExcel = event => {
    const { files } = event.target;
    if (files.length === 1) {
      processFileContent(files[0]);
      // const serviceList = processFileContent(files[0]);
      // serviceList.map(addService);
      // setuploadModal(false);
    }
  };

  const processFileContent = async file => {
    const fileReader = new FileReader();
    fileReader.onload = async event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: "binary" });
        const first_sheet_name = workbook.SheetNames[0];
        /* Get worksheet */
        const worksheet = workbook.Sheets[first_sheet_name];
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: "A",
          raw: true,
          defval: null
        });
        const results = data.map(extractData);
        const completed = await Promise.all(results);
        // Remove company header fom file
        const removedHeader = completed.slice(1);
        removedHeader.map(addService);
        message.success("Arquivo carregado com sucesso!");
        setuploadModal(false);
        // addImportedServices(removedHeader);
      } catch (e) {
        message = message.error("file type is incorrect!");
      }
    };
    fileReader.readAsBinaryString(file);
  };

  return (
    <>
      <Row gutter={16} type="flex" style={{ marginBottom: 30 }}>
        <Col span={4} />
        <Col span={16}>
          <Row gutter={2}>
            <Col span={8}>
              <Button
                style={{ width: "100%" }}
                onClick={() => setuploadModal(true)}
              >
                Importar Excel
              </Button>
            </Col>
            <Col span={8}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => setModal(true)}
              >
                Adicionar serviço
              </Button>
            </Col>
            <Col span={8}>
              <LoadServices loadServices={loadServices} />
            </Col>
          </Row>
        </Col>
        <Col span={4} />
      </Row>

      {
        <Modal
          visible={uploadModal}
          footer={null}
          width={900}
          onCancel={() => setuploadModal(false)}
        >
          <h1>Importar Clientes</h1>

          <input
            className="file-uploader"
            type="file"
            accept=".xlsx, .xls"
            onChange={onImportExcel}
          />

          <Button type="primary" disabled={true} style={{ float: "right" }}>
            Importar endereços
          </Button>
        </Modal>
      }
      <OperationalForm />

      <Modal
        title="Adicionar Serviço"
        visible={modal}
        width={900}
        onCancel={() => setModal(false)}
        footer={null}
      >
        <ServiceForm />
      </Modal>

      <div style={{ background: "#ECECEC", padding: "30px" }}>
        <Row gutter={16}>
          <Table
            rowKey={record => record.id}
            columns={[
              ...columns,
              {
                title: "Ações",
                key: "action",
                render: (text, record) => (
                  <>
                    <Popconfirm
                      title="Confirmar remoção?"
                      onConfirm={_ => deleteService(record.id)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Icon type="delete" />
                    </Popconfirm>
                  </>
                )
              }
            ]}
            dataSource={services}
            pagination={true}
            locale={{ emptyText: "Nenhum serviço inserido" }}
          />
        </Row>
      </div>
    </>
  );
};

const client = createClient({
  url: "https://ikt-router-server.herokuapp.com/v1/graphql"
});

export default () => (
  <Provider value={client}>
    <StepOne />
  </Provider>
);

const formatDate = date => {
  return moment(date)
    .format("DD/MM/YYYY")
    .toString();
};

const LoadServices = ({ loadServices }) => {
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());

  const [{ data = { service: [] }, fetching }] = useQuery({
    query: `{
      service(where: {id: {_eq: "higtop"}}) {
        id
        services
        updated_at
      }
    }`
  });

  const openModal = () => setModal(true);
  const handleHide = () => setModal(false);

  const onChange = ([startDate, finishDate]) => {
    setStartDate(startDate.toDate());
    setFinishDate(finishDate.toDate());
  };

  const disabledButton = !(data.service.length > 0);

  const filteredServices =
    data.service.length > 0
      ? JSON.parse(data.service[0].services).filter(
          ({ date }) =>
            new Date(date) <= finishDate && new Date(date) >= startDate
        )
      : [];

  const handleSubmit = () => {
    loadServices(filteredServices);
    setModal(false);
  };

  return (
    <>
      <Button
        disabled={disabledButton}
        style={{
          width: "100%"
        }}
        onClick={openModal}
      >
        Carregar serviços
      </Button>
      <Modal
        width={800}
        title="Filtrar serviços"
        visible={modal}
        onOk={handleSubmit}
        onCancel={handleHide}
      >
        <RangePicker onChange={onChange} />
        <Table
          rowKey={row => JSON.stringify(row)}
          dataSource={filteredServices}
          loading={fetching}
        >
          <Table.Column title="Cliente" dataIndex="client" key="client" />
          <Table.Column title="Endereço" dataIndex="address" key="address" />
          <Table.Column title="Peso" dataIndex="weight" key="weight" />
          <Table.Column title="Volume" dataIndex="volume" key="volume" />
          <Table.Column
            title="Data"
            dataIndex="date"
            key="date"
            render={formatDate}
          />
        </Table>
      </Modal>
    </>
  );
};
