export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getGeocode = async address => {
  // TODO: add key to config file
  const API_KEY = "AIzaSyAkqJCdLApF2hFDiXPcMs9tQtnEn6WGZek";
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`
  );
  const data = await response.json();
  return data.status === "OK" ? data.results[0].geometry.location : null;
};
