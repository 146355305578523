import gql from "graphql-tag";

export const VEHICLE_FRAGMENT = gql`
  fragment Vehicle on vehicle {
    id
    type
    year
    plate
    model
    brand
    maximum_weight
    maximum_volume
  }
`;

export const ALL_VEHICLES_QUERY = gql`
  ${VEHICLE_FRAGMENT}
  query AllVehicles {
    vehicle {
      ...Vehicle
    }
  }
`;

export const ALL_VEHICLES_SUBSCRIPTION = gql`
  ${VEHICLE_FRAGMENT}
  subscription AllVehicles {
    vehicle {
      ...Vehicle
    }
  }
`;

export const VEHICLE_BY_PK = gql`
  ${VEHICLE_FRAGMENT}
  query($id: uuid!) {
    vehicle_by_pk(id: $id) {
      ...Vehicle
    }
  }
`;

export const INSERT_VEHICLE_MUTATION = gql`
  mutation($values: vehicle_insert_input!) {
    insert_vehicle(objects: [$values]) {
      affected_rows
    }
  }
`;

export const UPDATE_VEHICLE_MUTATION = gql`
  ${VEHICLE_FRAGMENT}
  mutation($id: uuid!, $values: vehicle_set_input!) {
    update_vehicle(_set: $values, where: { id: { _eq: $id } }) {
      returning {
        ...Vehicle
      }
    }
  }
`;

export const DELETE_VEHICLE_MUTATION = gql`
  mutation($id: uuid!) {
    delete_vehicle(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
