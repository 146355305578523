import gql from "graphql-tag";

export const DRIVER_FRAGMENT = gql`
  fragment Driver on driver {
    id
    cnh
    cpf
    name
    birthday
    cnh_category
  }
`;

export const ALL_DRIVERS_QUERY = gql`
  ${DRIVER_FRAGMENT}
  query AllDrivers {
    driver {
      ...Driver
    }
  }
`;

export const ALL_DRIVERS_SUBSCRIPTION = gql`
  ${DRIVER_FRAGMENT}
  subscription AllDrivers {
    driver {
      ...Driver
    }
  }
`;

export const DRIVER_BY_PK = gql`
  ${DRIVER_FRAGMENT}
  query($id: uuid!) {
    driver_by_pk(id: $id) {
      ...Driver
    }
  }
`;

export const INSERT_DRIVER_MUTATION = gql`
  mutation($values: driver_insert_input!) {
    insert_driver(objects: [$values]) {
      affected_rows
    }
  }
`;

export const UPDATE_DRIVER_MUTATION = gql`
  ${DRIVER_FRAGMENT}
  mutation($id: uuid!, $values: driver_set_input!) {
    update_driver(_set: $values, where: { id: { _eq: $id } }) {
      returning {
        ...Driver
      }
    }
  }
`;

export const DELETE_DRIVER_MUTATION = gql`
  mutation($id: uuid!) {
    delete_driver(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
