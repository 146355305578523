import { useQuery } from "@apollo/react-hooks";
import {
  Form,
  Input,
  InputNumber,
  Select,
  SubmitButton
} from "formik-antd";
import { Button, Col, Icon, message, Row, Spin } from "antd";
import { Formik, FormikActions, FormikValues } from "formik";
import range from "lodash/range";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import client from "../../apolloClient";
import history from "../../history";
import { IVehicle } from "../../types/Vehicle";
import {
  INSERT_VEHICLE_MUTATION,
  UPDATE_VEHICLE_MUTATION,
  VEHICLE_BY_PK
} from "./VehiclesQueries";

const Option = Select.Option;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 7 }
};

const vehicleSchema = Yup.object().shape({
  plate: Yup.string()
    .min(7, "Placa deve conter 7 digitos")
    .max(7, "Placa deve conter 7 digitos")
    .required("Placa é um campo obrigatório"),
  model: Yup.string().required("Modelo é um campo obrigatório"),
  year: Yup.number().required("Ano é um campo obrigatório"),
  brand: Yup.string().required("Marca é um campo obrigatório"),
  type: Yup.string().required("Tipo é um campo obrigatório"),
  maximum_weight: Yup.number().required("Peso máximo é um campo obrigatório"),
  maximum_volume: Yup.number().required("Volume máximo é um campo obrigatório")
});

const handleSave = async (
  values: FormikValues,
  { setSubmitting }: FormikActions<IVehicle>
) => {
  const { id, ...rest } = values;
  const { errors } = await client.mutate({
    mutation: INSERT_VEHICLE_MUTATION,
    variables: { values: { ...rest } }
  });

  !errors
    ? message.success("Veículo salvo com sucesso")
    : message.error("Falha ao salvar veículo");

  setSubmitting(false);
  history.push("/vehicles/");
};

const handleEdit = async (
  values: FormikValues,
  { setSubmitting }: FormikActions<IVehicle>
) => {
  const { id, ...rest } = values;

  const { errors } = await client.mutate({
    mutation: UPDATE_VEHICLE_MUTATION,
    variables: { id, values: { ...rest } }
  });

  !errors
    ? message.success("Veículo editado com sucesso")
    : message.error("Falha ao editar veículo");

  setSubmitting(false);
  history.push("/vehicles/");
};

const VehicleForm = (props: any) => {
  const { id } = props.match.params;
  const editMode = Boolean(id);
  const onSubmit = id ? handleEdit : handleSave;
  const { loading, data } = useQuery(VEHICLE_BY_PK, { variables: { id } });
  const vehicle = (data && data.vehicle_by_pk) || {
    id: "",
    plate: "",
    model: "",
    year: "",
    brand: "",
    type: "Próprio",
    maximum_weight: 0,
    maximum_volume: 0
  };

  if (loading) return <Spin size="large" />;

  return (
    <div>
      <Row style={{ paddingBottom: "20px" }}>
        <Col span={8}>
          <Link to="/vehicles">
            <Button>
              {" "}
              <Icon type="left" /> Voltar
            </Button>
          </Link>
        </Col>
        <Col span={8}>
          <h1 style={{ textAlign: "center" }}>
            {editMode ? "Editar" : "Adicionar"} Veículo
          </h1>
        </Col>
        <Col span={8} />
      </Row>
      <div>
        <Formik
          onSubmit={onSubmit}
          initialValues={vehicle}
          validationSchema={vehicleSchema}
        >
          <Form {...formItemLayout}>
            <Form.Item name="plate" label="Placa">
              <Input name="plate" placeholder="Placa" />
            </Form.Item>

            <Form.Item name="type" label="Tipo">
              <Select name="type" placeholder="Tipo">
                <Option value="Próprio">Próprio</Option>
                <Option value="Terceiro">Terceiro</Option>
                <Option value="Diretoria">Diretoria</Option>
              </Select>
            </Form.Item>

            <Form.Item name="brand" label="Marca">
              <Input name="brand" placeholder="Marca" />
            </Form.Item>

            <Form.Item name="model" label="Modelo">
              <Input name="model" placeholder="Modelo" />
            </Form.Item>

            <Form.Item name="year" label="Ano">
              <Select name="year" placeholder="Ano">
                {range(
                  new Date().getFullYear(),
                  new Date().getFullYear() - 25
                ).map(item => (
                  <Option key={item} value={`${item}`}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="maximum_weight" label="Peso máximo (Kg)">
              <InputNumber
                style={{ width: "100%" }}
                name="maximum_weight"
                placeholder="Peso máximo (Kg)"
              />
            </Form.Item>

            <Form.Item name="maximum_volume" label="Volume máximo (m³)">
              <InputNumber
                style={{ width: "100%" }}
                name="maximum_volume"
                placeholder="Volume máximo (m³)"
              />
            </Form.Item>

            <Form.Item name="submit">
              <SubmitButton type="primary">Enviar</SubmitButton>
            </Form.Item>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default VehicleForm;
