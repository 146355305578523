import { Table } from "antd";
import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ALL_VEHICLES_QUERY } from "../vehicles/VehiclesQueries";
import { ServicesContext } from "../../ServicesContext";
import { IVehicle } from "../../types/Vehicle";

const columns = [
  {
    title: "Placa",
    dataIndex: "plate"
  },
  {
    title: "Modelo",
    dataIndex: "model"
  },
  {
    title: "Ano",
    dataIndex: "year"
  },
  {
    title: "Tipo",
    dataIndex: "type"
  },
  {
    title: "Peso",
    dataIndex: "maximum_weight",
    render: (text: string) => `${text} Kg`
  },
  {
    title: "Volume",
    dataIndex: "maximum_volume",
    render: (text: string) => `${text} m³`
  }
];

const StepTwo = () => {
  const { setSelectedVehicles, selectedVehicles } = useContext(ServicesContext);

  const { loading, data } = useQuery(ALL_VEHICLES_QUERY); // TODO: load data from a store
  const vehicles = data.vehicle || [];

  const rowSelection = {
    onChange: (_: any, selectedRows: any) => setSelectedVehicles(selectedRows),
    selectedRowKeys: selectedVehicles.map(({ id }) => id)
  };

  return (
    <div style={{ padding: "30px" }}>
      <Table
        rowSelection={rowSelection}
        rowKey={(record: IVehicle) => record.id}
        columns={columns}
        loading={loading}
        dataSource={vehicles}
      />
    </div>
  );
};
export default StepTwo;
