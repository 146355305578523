import React, { useContext } from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Service } from "./Service";
import { Select, Row, Col } from "antd";
import { ServicesContext } from "../../ServicesContext";

const { Option } = Select;

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 400px;
  display: inline-block;

  flex-directions: column;
`;

const ServiceList = styled.div`
  padding: 8px;
  transition: backgroud-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? "skyblue" : "white")};
  flex-grow: 1;
  min-height: 500px;
`;

const Column = ({ vehicles, drivers, route, columnId }) => {
  const { selectDriver, selectVehicle } = useContext(ServicesContext);

  const totalWeight = route.services
    .map(s => Number(s.weight))
    .reduce((a, b) => a + b, 0);
  const totalVolume = route.services
    .map(s => Number(s.volume))
    .reduce((a, b) => a + b, 0);

  const overWheight =
    route.vehicle && route.vehicle.maximum_weight < totalWeight ? true : false;
  const overVolume =
    (route.vehicle && route.vehicle.maximum_volume) < totalVolume;

  const handleSelectVehicle = (vehicleId, columnId) => {
    const vehicle = vehicles.find(({ id }) => id === vehicleId);
    selectVehicle(vehicle, columnId);
  };

  const handleSelectDriver = (driverId, columnId) => {
    const driver = drivers.find(({ id }) => id === driverId);
    selectDriver(driver, columnId);
  };

  return (
    <Container>
      <div
        style={{
          margin: "8px",
          background: "#ECECEC",
          padding: "8px"
        }}
      >
        <span>
          <b>Veículo: </b>
        </span>
        <Select
          allowClear={true}
          onChange={vehicleId => handleSelectVehicle(vehicleId, columnId)}
          placeholder="Selecione Veiculo"
          defaultValue={route.vehicle && route.vehicle.id}
          style={{ width: 200 }}
        >
          {vehicles &&
            [route.vehicle, ...vehicles].filter(Boolean).map(vehicle => (
              <Option value={vehicle.id} key={vehicle.id}>
                {vehicle.plate}
              </Option>
            ))}
        </Select>
        <br />
        <span>
          <b>Motorista: </b>
        </span>
        <Select
          allowClear={true}
          onSelect={driverId => handleSelectDriver(driverId, columnId)}
          placeholder="Selecione Motorista"
          defaultValue={route.driver && route.driver.id}
          style={{ width: 200 }}
        >
          {drivers &&
            [route.driver, ...drivers].filter(Boolean).map(driver => (
              <Option value={driver.id} key={driver.id}>
                {driver.name}
              </Option>
            ))}
        </Select>
        <div>
          <span>
            <b>Numero de itens: </b>
            {route.services.length}
          </span>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <span>
                <b>Distancia: </b>
                {route.distance} Km
              </span>
            </Col>
            <Col className="gutter-row" span={12}>
              <span>
                <b>Tempo: </b>
                {route.time} h
              </span>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <span style={{ backgroundColor: overWheight ? "red" : "" }}>
                <b>Peso Total: </b>
                {totalWeight} Kg
              </span>
            </Col>
            <Col className="gutter-row" span={12}>
              <span style={{ backgroundColor: overVolume ? "red" : "" }}>
                <b>Volume Total: </b>
                {totalVolume} m³
              </span>
            </Col>
          </Row>
        </div>
      </div>
      <Droppable droppableId={columnId}>
        {(provided, snapshot) => (
          <ServiceList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {route.services.map((service, index) => (
              <Service key={index} service={service} index={index} />
            ))}
            {provided.placeholder}
          </ServiceList>
        )}
      </Droppable>
    </Container>
  );
};

export default Column;
