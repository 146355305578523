import gql from "graphql-tag";

// id
export const CONFIGURATION_FRAGMENT = gql`
  fragment Configuration on configuration {
    id
    service_start
    service_finish
    operational_limit
    operational_duration
    updated_at
  }
`;

export const ALL_CONFIGURATIONS = gql`
  ${CONFIGURATION_FRAGMENT}
  query AllConfigurations {
    configuration {
      ...Configuration
    }
  }
`;

export const CONFIG_QUERY = gql`
  ${CONFIGURATION_FRAGMENT}
  query($id: uuid!) {
    configuration_by_pk(id: $id) {
      ...Configuration
    }
  }
`;

export const UPDATE_CONFIG_MUTATION = gql`
  ${CONFIGURATION_FRAGMENT}
  mutation($id: uuid!, $values: configuration_set_input!) {
    update_configuration(_set: $values, where: { id: { _eq: $id } }) {
      returning {
        ...Configuration
      }
    }
  }
`;
