import { ApolloProvider } from "@apollo/react-hooks";
import { InfleetProvider, useAuth } from "@infleet/core";
import { Button, Icon, Layout, Menu } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { Link, Router } from "react-router-dom";
import client from "./apolloClient";
import history from "./history";
import Routes from "./modules/Routes";

const { Header, Content, Sider } = Layout;

const App = () => {
  const { logout } = useAuth();

  const handleSignout = () => {
    console.log("clicked");
    logout();
  };

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <Layout>
          <Header
            style={{
              backgroundColor: "white",
              position: "fixed",
              zIndex: 1,
              width: "100%"
            }}
          >
            <Button
              onClick={handleSignout}
              type="link"
              style={{ float: "right", marginTop: 20 }}
            >
              <Icon type="logout" />
              <span style={{ marginLeft: 10 }} className="nav-text">
                Sair
              </span>
            </Button>
            <p style={{ color: "#001132" }}>INTERAKT | Roteirizador</p>
          </Header>
          <br />
          <br />
          <br />
          <Layout>
            <Sider
              style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0
              }}
            >
              <Menu theme="dark" mode="inline">
                <Menu.Item key="1">
                  <Link to="/">
                    <Icon type="compass" />
                    <span className="nav-text">Roteirizador</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/clients/">
                    <Icon type="book" />
                    <span className="nav-text">Clientes</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/vehicles/">
                    <Icon type="car" />
                    <span className="nav-text">Veículos</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to="/drivers/">
                    <Icon type="team" />
                    <span className="nav-text">Motoristas</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/config/">
                    <Icon type="control" />
                    <span className="nav-text">Configurações</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout style={{ marginLeft: 200 }}>
              <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                <div
                  style={{
                    padding: 24,
                    background: "#fff"
                  }}
                >
                  <Routes />
                </div>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </Router>
    </ApolloProvider>
  );
};

export default () => (
  <InfleetProvider appId="router">
    <App />
  </InfleetProvider>
);
