import { useSubscription } from "@apollo/react-hooks";
import {
  Button,
  Col,
  Divider,
  Icon,
  message,
  Popconfirm,
  Row,
  Table,
  Typography
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import client from "../../apolloClient";
import {
  ALL_DRIVERS_SUBSCRIPTION,
  DELETE_DRIVER_MUTATION
} from "./DriversQueries";

const { Column } = Table;
const { Title } = Typography;

const handleDelete = async (id: string) => {
  const { errors } = client.mutate({
    mutation: DELETE_DRIVER_MUTATION,
    variables: { id }
  });

  !errors
    ? message.success("Motorista removido com sucesso")
    : message.warn("Falha ao remover motorista");
};

const DriversList = () => {
  const { loading, data = { driver: [] } } = useSubscription(
    ALL_DRIVERS_SUBSCRIPTION
  );

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Row style={{ paddingBottom: "20px" }}>
          <Col span={8}>
            <Link to="/drivers/new">
              <Button type="primary">Adicionar Motorista</Button>
            </Link>
          </Col>
          <Col span={8}>
            <Title level={2} style={{ textAlign: "center" }}>
              Motoristas
            </Title>
          </Col>
          <Col span={8} />
        </Row>
      </div>

      <Table
        rowKey="id"
        loading={loading}
        dataSource={data.driver}
        locale={{ emptyText: "Nenhum motorista cadastrado" }}
      >
        <Column title="Nome" dataIndex="name" key="name" />
        <Column
          title="CPF"
          dataIndex="cpf"
          key="cpf"
          render={text =>
            text.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
          }
        />
        <Column
          title="Data de nascimento"
          dataIndex="birthday"
          key="birthday"
          render={text => new Date(text).toLocaleDateString("pt-BR")}
        />
        <Column title="CNH" dataIndex="cnh" key="cnh" />
        <Column
          title="Categoria CNH"
          dataIndex="cnh_category"
          key="cnh_category"
        />
        <Column
          title="Ações"
          key="action"
          render={(_, { id }) => (
            <span>
              <Link to={`/drivers/${id}`}>
                <Icon type="edit" />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title="Confirmar remoção?"
                onConfirm={_ => handleDelete(id)}
                okText="Sim"
                cancelText="Não"
              >
                <Icon type="delete" />
              </Popconfirm>
            </span>
          )}
        />
      </Table>
    </>
  );
};

export default DriversList;
