import { Button, Col, Drawer, Icon, Row, Steps } from "antd";
import React, { useState, useContext } from "react";
import MapWrapper from "../MapWrapper";
import StepFour from "./StepFour";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { ServicesContext } from "../../ServicesContext";

const Step = Steps.Step;
const steps = [
  {
    title: "Passo 1",
    content: "First-content",
    component: StepOne
  },
  {
    title: "Passo 2",
    content: "Second-content",
    component: StepTwo
  },
  {
    title: "Passo 3",
    content: "Terceiro",
    component: StepThree
  },
  {
    title: "Passo 4",
    content: "Last-content",
    component: StepFour
  }
];

const Stepper = () => {
  const [current, setCurrent] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => setDrawer(value => !value);
  const prev = () => setCurrent(current => current - 1);
  const next = () => setCurrent(current => current + 1);
  const goToStep = step => setCurrent(step);
  const CurrentComponent = steps[current].component;
  const { loadConfigurations } = useContext(ServicesContext);
  loadConfigurations();

  return (
    <div>
      <Row style={{ marginBottom: 30 }}>
        <Button
          style={{ float: "right" }}
          type="primary"
          onClick={toggleDrawer}
        >
          Mostrar Mapa
        </Button>
      </Row>
      <div style={{ marginBottom: 30 }}>
        <Steps current={current}>
          {steps.map((item, key) => (
            <Step
              style={{ cursor: "pointer" }}
              key={item.title}
              title={item.title}
              onClick={_ => goToStep(key)}
            />
          ))}
        </Steps>
      </div>
      <Row gutter={8} type="flex" justify="space-around" align="middle">
        <Col span={1}>
          {current > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={prev}>
              <Icon type="left" />
            </Button>
          )}
        </Col>
        <Col span={22}>
          <CurrentComponent />
        </Col>
        <Col span={1}>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              <Icon type="right" />
            </Button>
          )}
        </Col>

        <Drawer title="Map" width={850} onClose={toggleDrawer} visible={drawer}>
          <MapWrapper />
        </Drawer>

        <div className="steps-action" />
      </Row>
    </div>
  );
};

export default Stepper;
