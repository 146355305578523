import gql from "graphql-tag";

export const CLIENT_FRAGMENT = gql`
  fragment Client on client {
    id
    name
    cnpj
    locations {
      id
      description
      address
      lat
      lng
      service_start
      service_finish
    }
  }
`;

export const CLIENT_BY_PK = gql`
  ${CLIENT_FRAGMENT}
  query($id: uuid!) {
    client_by_pk(id: $id) {
      ...Client
    }
  }
`;
