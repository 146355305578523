import HomeIcon from "../assets/home.svg";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { flatten } from "lodash";
import React, { useContext, useState } from "react";
import blueMarker from "../assets/blueMarker.png";
import greenMarker from "../assets/greenMarker.png";
import orangeMarker from "../assets/orangeMarker.png";
import redMarker from "../assets/redMarker.png";
import yellowMarker from "../assets/yellowMarker.png";
import { ServicesContext } from "../ServicesContext";

const MarkerMap = new Map([
  [0, redMarker],
  [1, blueMarker],
  [2, greenMarker],
  [3, yellowMarker],
  [4, orangeMarker]
]);

const MarkerWithInfoWindow = ({ marker }) => {
  const [open, setOpen] = useState(false);
  const onToggleOpen = () => setOpen(!open);

  return (
    <>
      <Marker
        icon={MarkerMap.get(marker.route)}
        label={`${marker.number}`}
        position={marker.location}
        onClick={onToggleOpen}
      />
      {open && (
        <InfoWindow position={marker.location} onCloseClick={onToggleOpen}>
          <div
            style={{
              background: `white`,
              border: `1px solid #ccc`,
              padding: 15
            }}
          >
            {marker.client && <h3>{marker.client.name}</h3>}
            <p>
              ({marker.location.description}) {marker.location.address}
            </p>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

const MapWrapper = () => {
  const {
    origin,
    services,
    destination,
    optimizedRoutes = { routes: [] }
  } = useContext(ServicesContext);

  const { routes } = optimizedRoutes;

  const markers = flatten(
    routes.map((route, key) => {
      return route.services.map((service, index) => ({
        location: service.location,
        route: key,
        number: index + 1
      }));
    })
  );

  return (
    <div className="map">
      <div className="map-container">
        <GoogleMap
          id="direction-example"
          mapContainerStyle={{
            height: "800px",
            width: "100%"
          }}
          zoom={3}
          center={{
            lat: 0,
            lng: -85
          }}
        >
          {origin.lat && origin.lng && (
            <Marker icon={HomeIcon} position={origin} />
          )}

          {destination.lat && destination.lng && (
            <Marker label={`Destino`} position={destination} />
          )}

          {markers.length
            ? markers.map((marker, key) => (
                <MarkerWithInfoWindow key={key} marker={marker} />
              ))
            : services.map((service, key) => (
                // <Marker key={index} position={service.location} />
                <MarkerWithInfoWindow key={key} marker={service} />
              ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default MapWrapper;
