import { useQuery } from "@apollo/react-hooks";
import { Icon, Table, Typography } from "antd";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const { Column } = Table;
const { Title } = Typography;

const CONFIG_QUERY = gql`
  query {
    configuration {
      id
      operational_limit
      operational_duration
      service_finish
      service_start
    }
  }
`;

const DriversList = () => {
  const { loading, data = { configuration: [] } } = useQuery(CONFIG_QUERY);

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Title level={2} style={{ textAlign: "center" }}>
          Configurações
        </Title>
      </div>

      <Table
        rowKey="id"
        loading={loading}
        pagination={false}
        dataSource={data.configuration}
        locale={{ emptyText: "Nenhuma configuração cadastrada" }}
      >
        <Column
          title="Limite Operacional"
          dataIndex="operational_limit"
          key="operational_limit"
          render={text => {
            const [hours, minutes, seconds] = text.split(":");
            return `${hours}:${minutes}h`;
          }}
        />
        <Column
          title="Duração de Serviço Padrão"
          dataIndex="operational_duration"
          key="operational_duration"
          render={text => {
            const [hours, minutes, seconds] = text.split(":");
            return `${hours}:${minutes}`;
          }}
        />
        <Column
          title="Inicio Padrão de Operação"
          dataIndex="service_start"
          key="service_start"
          render={text => {
            const [hours, minutes, seconds] = text.split(":");
            return `${hours}:${minutes}h`;
          }}
        />
        <Column
          title="Final Padrão de Operação"
          dataIndex="service_finish"
          key="service_finish"
          render={text => {
            const [hours, minutes, seconds] = text.split(":");
            return `${hours}:${minutes}h`;
          }}
        />
        <Column
          title="Ações"
          key="action"
          render={(_, { id }) => (
            <Link to={`/config/${id}`}>
              <Icon type="edit" />
            </Link>
          )}
        />
      </Table>
    </>
  );
};

export default DriversList;
