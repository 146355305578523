import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Card, Collapse } from "antd";

const { Panel } = Collapse;

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${props => (props.isDragging ? "lightgreen" : "white")};
`;

export const Service = props => {
  const { service, index } = props;
  return (
    <Draggable draggableId={service.id} index={index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <Card
            title={`${service.client.name || ""} (${service.location
              .description || ""})
            `}
            bordered={false}
            style={{ width: "100%", marginTop: 16 }}
          >
            <Collapse expandIconPosition="right">
              <Panel key={service.id} header="Detalhes">
                <p>Endereço: {service.location.address}</p>
                <p>Peso: {service.weight}</p>
                <p>Volume: {service.volume}</p>
                <p>Início da operação: {service.service_start}</p>
                <p>Fim da operação: {service.service_finish}</p>
                {service.estimatedArrival && (
                  <p>Chegada estimada: {service.estimatedArrival}</p>
                )}
                {service.estimatedDeparture && (
                  <p>Saída estimada: {service.estimatedDeparture}</p>
                )}
              </Panel>
            </Collapse>
          </Card>
        </Container>
      )}
    </Draggable>
  );
};
