import { useSubscription } from "@apollo/react-hooks";
import {
  Button,
  Col,
  Divider,
  Icon,
  message,
  Popconfirm,
  Row,
  Table,
  Typography
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import client from "../../apolloClient";
import { IVehicle } from "../../types/Vehicle";
import {
  ALL_VEHICLES_SUBSCRIPTION,
  DELETE_VEHICLE_MUTATION
} from "./VehiclesQueries";

const { Column } = Table;
const { Title } = Typography;

const handleDelete = async (id: string) => {
  const { errors } = client.mutate({
    mutation: DELETE_VEHICLE_MUTATION,
    variables: { id }
  });

  !errors
    ? message.success("Veículo removido com sucesso")
    : message.warn("Falha ao remover veículo");
};

const VehiclesList = () => {
  const { loading, data = { vehicle: [] } } = useSubscription(
    ALL_VEHICLES_SUBSCRIPTION
  );

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Row style={{ paddingBottom: "20px" }}>
          <Col span={8}>
            <Link to="/vehicles/new/">
              <Button type="primary">Adicionar Veículo</Button>
            </Link>
          </Col>
          <Col span={8}>
            <Title level={2} style={{ textAlign: "center" }}>
              Veículos
            </Title>
          </Col>
          <Col span={8} />
        </Row>
      </div>
      <Table
        rowKey="id"
        loading={loading}
        dataSource={data.vehicle}
        locale={{ emptyText: "Nenhum veículo cadastrado" }}
      >
        <Column title="Placa" dataIndex="plate" key="plate" />
        <Column title="Modelo" dataIndex="model" key="model" />
        <Column title="Ano" dataIndex="year" key="year" />
        <Column title="Marca" dataIndex="brand" key="brand" />
        <Column title="Tipo" dataIndex="type" key="type" />
        <Column
          align="right"
          title="Peso Maximo (Kg)"
          dataIndex="maximum_weight"
          key="maximum_weight"
        />
        <Column
          align="right"
          title="Volume Maximo (m³)"
          dataIndex="maximum_volume"
          key="maximum_volume"
        />
        <Column
          title="Ações"
          key="action"
          render={(text, record: IVehicle) => (
            <span>
              <Link to={`/vehicles/${record.id}`}>
                <Icon type="edit" />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title="Confirmar remoção?"
                onConfirm={_ => handleDelete(record.id)}
                okText="Sim"
                cancelText="Não"
              >
                <Icon type="delete" />
              </Popconfirm>
            </span>
          )}
        />
      </Table>
    </>
  );
};

export default VehiclesList;
