import { useQuery } from "@apollo/react-hooks";
import {
  Form,
  InputNumber,
  Select,
  SubmitButton,
  TimePicker
} from "formik-antd";
import { Col, Row, Spin } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext } from "react";
import * as Yup from "yup";
import { ServicesContext } from "../../ServicesContext";
import { CLIENT_QUERY } from "../clients/ClientsList";

const Option = Select.Option;

const format = "HH:mm";

const serviceSchema = Yup.object().shape({
  volume: Yup.number("Volume deve conter apenas numeros").required(
    "Campo obrigatório"
  ),
  weight: Yup.number("Peso deve conter apenas numeros").required(
    "Campo obrigatório"
  ),
  client: Yup.string()
    .required("Campo obrigatório")
    .nullable(),
  location: Yup.string()
    .required("Campo obrigatório")
    .nullable(),
  service_start: Yup.string().required("Campo obrigatório"),
  service_finish: Yup.string().required("Campo obrigatório"),
  operational_duration: Yup.string().required("Campo obrigatório")
});

const ServiceForm = () => {
  const { addService, configurations } = useContext(ServicesContext);
  const { loading, data } = useQuery(CLIENT_QUERY);
  const clients = data.client || []; // TODO: get list of clients from a local store instead of the database everytime

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const client = clients.find(c => c.id === values.client);
    const location = client.locations.find(l => l.id === values.location);
    addService({ ...values, client, location });
    resetForm();
    setSubmitting(false);
  };

  if (loading) return <Spin size="large" />;

  return (
    <Formik
      initialValues={{
        volume: 0,
        weight: 0,
        client: null,
        location: null,
        service_start: "08:00",
        service_finish: "18:00",
        operational_duration: configurations.operational_duration
      }}
      validationSchema={serviceSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Form.Item name="client" label="Cliente">
            <Select
              showSearch
              name="client"
              placeholder="Selecionar cliente"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {clients.map(client => (
                <Option key={client.id} value={client.id}>
                  {client.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {values.client && (
            <Form.Item name="location" label="Endereço">
              <Select
                showSearch
                name="location"
                placeholder="Selecionar endereço"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onSelect={location => {
                  const { service_start, service_finish } = clients
                    .find(({ id }) => id === values.client)
                    .locations.find(({ id }) => id === location);
                  setFieldValue("service_start", service_start);
                  setFieldValue("service_finish", service_finish);
                }}
              >
                {clients
                  .find(({ id }) => id === values.client)
                  .locations.map(location => (
                    <Option key={location.id} value={location.id}>
                      {`(${location.description}) ${location.address}`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="service_start" label="Início da Operação">
                <TimePicker
                  name="service_start"
                  style={{ width: "100%" }}
                  format={format}
                  defaultOpenValue={moment(values.service_start, format)}
                  // value={moment(values.service_start, format)}
                  onChange={(_, timeString) =>
                    setFieldValue("service_start", timeString)
                  }
                  value={moment(values.service_start, format)}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="service_finish" label="Fim da Operação">
                <TimePicker
                  style={{ width: "100%" }}
                  format={format}
                  defaultOpenValue={moment(values.service_finish, format)}
                  onChange={(_, timeString) =>
                    setFieldValue("service_finish", timeString)
                  }
                  value={moment(values.service_finish, format)}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-now" span={8}>
              <Form.Item name="operational_duration" label="Tempo de Serviço">
                <TimePicker
                  style={{ width: "100%" }}
                  format={format}
                  defaultOpenValue={moment(values.operational_duration, format)}
                  onChange={(_, timeString) =>
                    setFieldValue("operational_duration", timeString)
                  }
                  value={moment(values.operational_duration, format)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col className="gutter-now" span={12}>
              <Form.Item name="weight" label="Peso (Kg)">
                <InputNumber
                  name="weight"
                  style={{ width: "100%" }}
                  placeholder="Insira o peso (Kg)"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-now" span={12}>
              <Form.Item name="volume" label="Volume (m³)">
                <InputNumber
                  name="volume"
                  style={{ width: "100%" }}
                  placeholder="Insira o volume (m³)"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ float: "right" }} name="submit">
            <SubmitButton>Enviar</SubmitButton>
          </Form.Item>

          {/* FIX: Remove this workaround to fix modal size */}
          <div style={{ marginBottom: "30px" }} />
        </Form>
      )}
    </Formik>
  );
};

export default ServiceForm;
