import { useQuery } from "@apollo/react-hooks";
import {
  DatePicker,
  Form,
  Input,
  Radio,
  SubmitButton
} from "formik-antd";
import { Button, Col, Icon, message, Row, Spin } from "antd";
import pt_BR from "antd/lib/locale-provider/pt_BR";
import { Field, Formik, FormikActions, FormikValues, FieldProps } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import client from "../../apolloClient";
import history from "../../history";
import { IDriver } from "../../types/Driver";
import {
  DRIVER_BY_PK,
  INSERT_DRIVER_MUTATION,
  UPDATE_DRIVER_MUTATION
} from "./DriversQueries";

const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/
];

const CNH_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

const driverSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Nome muito curto")
    .max(255, "Nome muito longo")
    .required("Nome é um campo obrigatório"),
  cpf: Yup.string()
    .min(11, "CPF deve conter 11 dígitos")
    .max(11, "CPF deve conter 11 dígitos")
    .required("CPF é um campo obrigatório"),
  cnh: Yup.string()
    .min(11, "CNH deve conter 11 dígitos")
    .max(11, "CNH deve conter 11 dígitos")
    .required("CNH é um campo obrigatório"),
  birthday: Yup.string().required("Data de nascimento é um campo obrigatório"),
  cnh_category: Yup.string().required()
});

const handleSave = async (
  values: FormikValues,
  { setSubmitting }: FormikActions<IDriver>
) => {
  const { id, ...rest } = values;
  const { errors } = await client.mutate({
    mutation: INSERT_DRIVER_MUTATION,
    variables: { values: { ...rest } }
  });

  !errors
    ? message.success("Motorista salvo com sucesso")
    : message.error("Falha ao salvar motorista");

  setSubmitting(false);
  history.push("/drivers/");
};

const handleEdit = async (
  values: FormikValues,
  { setSubmitting }: FormikActions<IDriver>
) => {
  const { id, ...data } = values;

  const { errors } = await client.mutate({
    mutation: UPDATE_DRIVER_MUTATION,
    variables: { id, values: { ...data } }
  });

  !errors
    ? message.success("Motorista editado com sucesso")
    : message.warn("Falha ao editar motorista");

  setSubmitting(false);
  history.push("/drivers/");
};

const DriverForm = (props: any) => {
  const { id } = props.match.params;
  const editMode = Boolean(id);
  const onSubmit = id ? handleEdit : handleSave;
  const { loading, data } = useQuery(DRIVER_BY_PK, { variables: { id } });
  const driver = data.driver_by_pk || {
    id: "",
    name: "",
    cpf: "",
    birthday: new Date().toISOString(),
    cnh: "",
    cnh_category: "B"
  };

  if (loading) return <Spin size="large" />;

  return (
    <div>
      <Row style={{ paddingBottom: "20px" }}>
        <Col span={8}>
          <Link to="/drivers">
            <Button>
              {" "}
              <Icon type="left" /> Voltar
            </Button>
          </Link>
        </Col>
        <Col span={8}>
          <h1 style={{ textAlign: "center" }}>
            {editMode ? "Editar" : "Adicionar"} Motorista
          </h1>
        </Col>
        <Col span={8} />
      </Row>
      <div>
        <Formik
          initialValues={driver}
          validationSchema={driverSchema}
          onSubmit={onSubmit}
        >
          <Form {...formItemLayout}>
            <Form.Item name="name" label="Nome Completo">
              <Input name="name" placeholder="Nome completo" />
            </Form.Item>

            <Form.Item name="cpf" label="CPF">
              <Field name="cpf">
                {({ field, form }: FieldProps) => (
                  <MaskedInput
                    guide={false}
                    mask={CPF_MASK}
                    placeholder="000.000.000-00"
                    className="ant-input"
                    {...field}
                    onChange={({ target }) =>
                      form.setFieldValue(
                        field.name,
                        target.value.replace(/\D/g, "")
                      )
                    }
                  />
                )}
              </Field>
            </Form.Item>

            <Form.Item name="birthday" label="Data de Nascimento">
              <DatePicker
                style={{ width: "100%" }}
                locale={pt_BR}
                format="DD/MM/YYYY"
                name="birthday"
              />
            </Form.Item>

            <Form.Item name="cnh" label="CNH">
              <Field name="cnh">
                {({ field, form }: FieldProps) => (
                  <MaskedInput
                    guide={false}
                    mask={CNH_MASK}
                    placeholder="00000000000"
                    className="ant-input"
                    {...field}
                    onChange={({ target }) =>
                      form.setFieldValue(
                        field.name,
                        target.value.replace(/\D/g, "")
                      )
                    }
                  />
                )}
              </Field>
            </Form.Item>

            <Form.Item name="cnh_category" label="Categoria">
              <Radio.Group style={{ float: "left" }} name="cnh_category">
                <Radio.Button value={"B"}>B</Radio.Button>
                <Radio.Button value={"C"}>C</Radio.Button>
                <Radio.Button value={"D"}>D</Radio.Button>
                <Radio.Button value={"E"}>E</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="submit">
              <SubmitButton type="primary">Enviar</SubmitButton>
            </Form.Item>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default DriverForm;
