import { GoogleMap, Marker } from "@react-google-maps/api";
import { Button, Modal } from "antd";
import React, { useState } from "react";

export const ModalMapSelect = props => {
  const { location, onPlaceSelected } = props;

  const [modal, setModal] = useState(false);
  const dismissModal = () => setModal(false);

  const singleClick = ({ latLng }) => {
    const geocoder = new window.google.maps.Geocoder();
    const lat = latLng.lat();
    const lng = latLng.lng();
    let address = "";

    geocoder.geocode({ location: latLng }, function(results, status) {
      if (status === "OK") {
        if (results[0]) {
          address = results[0]["formatted_address"];
          onPlaceSelected({ lat, lng, address });
        } else {
          window.alert("No results found");
        }
      } else {
        onPlaceSelected({ lat, lng, address });
        window.alert("Geocoder failed due to: " + status);
      }
    });
  };

  const doubleClick = () => {
    return;
  };

  let clickCount = 0;
  let singleClickTimer = "";

  const handleClicks = event => {
    clickCount++;
    if (clickCount === 1) {
      singleClickTimer = setTimeout(() => {
        clickCount = 0;
        singleClick(event);
      }, 300);
    } else if (clickCount === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      doubleClick();
    }
  };

  return (
    <>
      <Button type="link" onClick={() => setModal(true)}>
        Selecionar endereço no mapa
      </Button>
      <Modal
        width={800}
        title="Selecionar endereço no mapa"
        footer={false}
        visible={modal}
        onCancel={dismissModal}
      >
        <GoogleMap
          id="direction-example"
          mapContainerStyle={{
            height: "400px",
            width: "100%"
          }}
          zoom={location.lat && location.lng ? 12 : 3}
          center={
            location.lat && location.lng
              ? location
              : {
                  lat: 0,
                  lng: -85
                }
          }
          onDblClick={handleClicks}
          onClick={handleClicks}
        >
          {location.lat && location.lng && <Marker position={location} />}
        </GoogleMap>
      </Modal>
    </>
  );
};
