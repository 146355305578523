import "antd/dist/antd.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ServicesContextProvider } from "../ServicesContext";
import ClientForm from "./clients/ClientForm";
import ClientsList from "./clients/ClientsList";
import ConfigForm from "./config/ConfigForm";
import ConfigList from "./config/ConfigList";
import DriverForm from "./drivers/DriverForm";
import DriversList from "./drivers/DriversList";
import Stepper from "./main/Stepper";
import VehicleForm from "./vehicles/VehiclesForm";
import VehiclesList from "./vehicles/VehiclesList";

const Routes = () => {
  return (
    <ServicesContextProvider>
      <Switch>
        <Route exact path="/" component={Stepper} />

        <Route exact path="/clients/" component={ClientsList} />
        <Route exact path="/clients/new/" component={ClientForm} />
        <Route path="/clients/:id" component={ClientForm} />

        <Route exact path="/vehicles/" component={VehiclesList} />
        <Route exact path="/vehicles/new/" component={VehicleForm} />
        <Route path="/vehicles/:id" component={VehicleForm} />

        <Route exact path="/drivers/" component={DriversList} />
        <Route exact path="/drivers/new" component={DriverForm} />
        <Route path="/drivers/:id" component={DriverForm} />

        <Route path="/config/:id" component={ConfigForm} />
        <Route path="/config/" component={ConfigList} />
      </Switch>
    </ServicesContextProvider>
  );
};

export default Routes;
