import { Form, TimePicker } from "formik-antd";
import { Col, Row } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext } from "react";
import AddressAutocomplete from "react-google-autocomplete";
import styled from "styled-components";
import { ServicesContext } from "../../ServicesContext";

const format = "HH:mm";

const Container = styled.div`
  padding: 30px;
  margin-bottom: 30px;
  background-color: #ececec;
`;

export const OperationalForm = () => {
  const {
    origin,
    destination,
    selectOrigin,
    serviceStart,
    serviceFinish,
    selectDestination,
    selectServiceStart,
    selectServiceFinish
  } = useContext(ServicesContext);

  return (
    <Container>
      <Formik>
        <Form>
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item name="origin" label="Origem">
                <AddressAutocomplete
                  className="ant-input"
                  type="text"
                  name="origin"
                  defaultValue={(origin && origin.address) || ""}
                  placeholder="Origem"
                  onPlaceSelected={selectOrigin}
                  types={["address"]}
                  componentRestrictions={{ country: "br" }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="serviceStart" label="Início da Operação">
                <TimePicker
                  style={{ width: "100%" }}
                  name="serviceStart"
                  format={format}
                  value={moment(serviceStart, format)}
                  onChange={selectServiceStart}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item name="destination" label="Destino">
                <AddressAutocomplete
                  className="ant-input"
                  type="text"
                  name="destination"
                  defaultValue={(destination && destination.address) || ""}
                  placeholder="Destino"
                  onPlaceSelected={selectDestination}
                  types={["address"]}
                  componentRestrictions={{ country: "br" }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="service_finish" label="Fim da Operação">
                <TimePicker
                  style={{ width: "100%" }}
                  name="service_finish"
                  format={format}
                  value={moment(serviceFinish, format)}
                  onChange={selectServiceFinish}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Container>
  );
};
